<template>
  <div class="animated fadeIn">
    <b-card no-body>
      <b-card-header>
        <i class="icon-note"></i> Edit Crypto Product
        <div class="card-header-actions">
          <a class="card-header-action" href="crypto-products" rel="noreferrer noopener">
            <small class="text-muted">Back</small>
          </a>
        </div>
      </b-card-header>
      <b-card-body>
        <div class="loading" v-if="isLoading == true">
            <div class="sk-three-bounce">
                <div class="sk-child sk-bounce1"></div>
                <div class="sk-child sk-bounce2"></div>
                <div class="sk-child sk-bounce3"></div>
            </div>
        </div>
        <b-row>
          <b-col sm="12">
            <!--<h6>Simple Form</h6>-->
            <b-form v-on:submit.prevent="onSubmit" novalidate>
              <!-- <div v-if="this.form.image != null" style="width:250px; height:250px">
                <b-img :src="this.form.image" fluid alt="Responsive image" style="width:250px; height:250px"></b-img>
              </div>
              <br>
              <b-form-group id="userNameInputGroup3"
                            label="Image"
                            label-for="userName">
                <b-form-file id="selectedImage"
                              :plain="true"
                              accept="image/*"
                              aria-describedby="imageFeedback"
                              v-model.lazy.trim="$v.form.selectedImage.$model"
                              :state="chkState('selectedImage')"
                              @change="onFileSelected">
                </b-form-file>
                <b-form-invalid-feedback id="imageFeedback" v-for="(error , index) in errors.form.image" :key="index">
                  - {{ error }} <br>
                </b-form-invalid-feedback>
              </b-form-group> -->
              <b-form-group id="name"
                              label="Name"
                              label-for="name">
                  <b-form-input id="name"
                              type="text"
                              v-model.lazy.trim="$v.form.name.$model"
                              :state="chkState('name')"
                              aria-describedby="nameFeedback"
                              placeholder="Please Enter Crypto Product Name"
                              autocomplete='given-name'
                              autofocus />
                  <b-form-invalid-feedback id="nameFeedback" >
                    <span v-if="!$v.form.name.required">- Name tidak boleh kosong!</span><br>
                  </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group id="short"
                              label="Short Name"
                              label-for="short-name">
                  <b-form-input id="short-name"
                              type="text"
                              v-model.lazy.trim="$v.form.short_name.$model"
                              :state="chkState('short_name')"
                              aria-describedby="shortNameFeedback"
                              placeholder="Please Enter Crypto Product Short Name"
                              autocomplete='given-name'
                              autofocus />
                  <b-form-invalid-feedback id="shortNameFeedback" >
                    <span v-if="!$v.form.short_name.required">- Short Name tidak boleh kosong!</span><br>
                  </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group id="symbol"
                              label="Symbol"
                              label-for="symbol-name">
                  <b-form-input id="symbol-name"
                              type="text"
                              v-model.lazy.trim="$v.form.symbol.$model"
                              :state="chkState('symbol')"
                              aria-describedby="symbolFeedback"
                              placeholder="Please Enter Crypto Product Symbol"
                              autocomplete='given-name'
                              autofocus />
                  <b-form-invalid-feedback id="symbolFeedback" >
                    <span v-if="!$v.form.symbol.required">- Short tidak boleh kosong!</span><br>
                  </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group id="group-amount"
                              label="Minimum Buy"
                              label-for="amount">
                  <b-form-input id="amount"
                              type="number"
                              v-model.lazy.trim="$v.form.minimum_buy.$model"
                              :state="chkState('minimum_buy')"
                              aria-describedby="amountFeedback"
                              placeholder="0"
                              autocomplete='given-name'
                              autofocus />
                  <b-form-invalid-feedback id="amountFeedback" >
                    <span v-if="!$v.form.minimum_buy.required">- Minimum Buy tidak boleh kosong!</span><br>
                    <span v-if="!$v.form.minimum_buy.numeric">- Minimum Buy harus berupa angka valid.</span>
                  </b-form-invalid-feedback>
              </b-form-group>
              <!-- <b-form-group id="group-special-price"
                              label="Special Price"
                              label-for="special-price">
                  <b-form-input id="special-price"
                              type="number"
                              v-model.lazy.trim="$v.form.special_price.$model"
                              :state="chkState('special_price')"
                              aria-describedby="specialPriceFeedback"
                              placeholder="0"
                              autocomplete='given-name'
                              autofocus />
                  <b-form-invalid-feedback id="specialPriceFeedback" >
                    <span v-if="!$v.form.special_price.numeric">- Special Price harus berupa angka valid.</span>
                  </b-form-invalid-feedback>
              </b-form-group> -->
              <b-form-group id="group-quantity"
                              label="Quantity"
                              label-for="quantity">
                  <b-form-input id="quantity"
                              type="number"
                              v-model.lazy.trim="$v.form.quantity.$model"
                              :state="chkState('quantity')"
                              aria-describedby="quantityFeedback"
                              placeholder="0"
                              autocomplete='given-name'
                              autofocus />
                  <b-form-invalid-feedback id="quantityFeedback" >
                    <span v-if="!$v.form.quantity.numeric">- Quantity harus berupa angka valid.</span>
                  </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group label="Status"
                  label-for="basicInlineCheckboxess">
                <b-form-checkbox-group id="basicInlineCheckboxess" name="InlineCheckboxes" :plain="true" v-model="form.status">
                  <b-form-checkbox :plain="true" :value="true">Active</b-form-checkbox>
                </b-form-checkbox-group>
              </b-form-group>
              <b-form-group label="Greeting Active"
                  label-for="basicInlineCheckboxess">
                <b-form-checkbox-group id="basicInlineCheckboxess" name="InlineCheckboxes" :plain="true" v-model="form.greeting_active">
                  <b-form-checkbox :plain="true" :value="true">Active</b-form-checkbox>
                </b-form-checkbox-group>
              </b-form-group>
              <b-form-group id="greeting"
                              v-if="form.greeting_active === 0 || form.greeting_active === '0' || form.greeting_active === true"
                              label="Greeting Text"
                              label-for="greeting">
                  <b-form-input id="greeting"
                              type="text"
                              v-model="form.greeting_text"
                              aria-describedby="greetingFeedback"
                              placeholder="Please Enter Greeting Text"
                              autocomplete='given-name'
                              autofocus />
              </b-form-group>
              <b-button type="submit" variant="primary">
                Submit
              </b-button>
            </b-form>
            <br/>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate"
import { required, numeric } from "vuelidate/lib/validators"

export default {
  name: "Edit-Crypto-Product",
  data() {
    return {
      form: {
        selectedImage: '',
        link: '',
        type: '',
        name: '',
        short_name: '',
        symbol: '',
        status: false,
        greeting_active: false,
        greeting_text: '',
        minimum_buy: 0,
        special_price: 0,
        quantity: 0
      },
      status: '',
      origin: '',
      errors: {
        message: [],
        status: [],
        code: '',
        headers: [],
        form: {
          image: [],
        },
      },
      isLoading: false
    }
  },
  computed: {
    formStr() { return JSON.stringify(this.form, null, 4) },
    isValid() { return !this.$v.form.$anyError },
    isDirty() { return this.$v.form.$anyDirty },
    invCheck() { return 'You must accept before submitting' },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      name: {
        required
      },
      short_name: {
        required
      },
      symbol: {
        required
      },
      minimum_buy: {
        required,
        numeric
      },
      // special_price: {
      //   numeric
      // },
      quantity: {
        numeric
      }
    }
  },
  created(){

    this.$http.get(`crypto-product/` + this.$route.params.id)
    .then((res) => {
      let result = res.data.data
      // this.form.image = result.image_url;
      // this.origin = result.origin;
      this.form.name = result.name
      this.form.short_name = result.short_name
      this.form.symbol = result.symbol
      this.form.amount = result.amount
      this.form.status = result.status
      this.form.greeting_active = result.greeting_active
      this.form.type = result.type.hashed_id
      this.form.minimum_buy = result.minimum_buy
      this.form.greeting_text = result.greeting_text
      this.form.special_price = result.special_price
      this.form.quantity = result.base_quantity
    }).catch((error) => {
      if (error.response) {
        this.errors.code = error.response.data.meta.code;
        this.errors.status = error.response.status;
        this.errors.headers = error.response.headers;
      }
    })
  },
  methods: {
    onFileSelected(event) {
      this.form.selectedImage = event.target.files[0];
    },
    onSubmit() {
      this.validate()
      this.isLoading = true
          const formData =  new FormData();
          // if (this.form.selectedImage == '') {
          //   formData.append('image', this.origin)
          // }else{
          //   formData.append('image', this.form.selectedImage, this.form.selectedImage.name)
          // }
          formData.append('name', this.form.name)
          formData.append('short_name', this.form.short_name)
          formData.append('symbol', this.form.symbol)
          formData.append('minimum_buy', this.form.minimum_buy)
          formData.append('merchant', this.form.type)
          formData.append('status', this.form.status == false ? '1' : '0')
          formData.append('greeting_active', this.form.greeting_active == false ? '1' : '0')
          formData.append('greeting_text', this.form.greeting_text)
          formData.append('special_price', this.form.special_price)
          formData.append('quantity', this.form.quantity)
          formData.append('_method', 'PUT')
          this.$http.post(`crypto-product/` + this.$route.params.id, formData)
          .then((result) => {
            this.isLoading = false
            this.$router.push("/crypto-products");
            this.$toasted.success(result.data.meta.message)
          }).catch((error) => {
            if (error.response) {
              this.isLoading = false
              this.errors.code = error.response.status;
              this.errors.status = error.response.data.meta.code;
              this.errors.headers = error.response.headers;
              if(this.errors.status == 400) {
                this.$toasted.error('Please fill out the form that must be required')
                this.errors.message = error.response.data.meta.message;
                this.errors.form.title = this.errors.message.title;
                this.errors.form.content = this.errors.message.content;
                this.errors.form.image = this.errors.message.image;
                this.errors.form.category = this.errors.message.category;
                this.errors.form.related = this.errors.message.type_id;
                this.$swal.fire(
                  'Failed!',
                  error.response.data.meta.message,
                  'error'
                )
              } else if (this.errors.status == 422) {
                this.$swal.fire(
                  'Failed!',
                  error.response.data.meta.message.image[0],
                  'error'
                )
              }
            }
          })
    },
    chkState(val) {
      const field = this.$v.form[val]
      return !field.$dirty || !field.$invalid
    },
    findFirstError(component = this) {
      if (component.state === false) {
        if (component.$refs.input) {
          component.$refs.input.focus()
          return true
        }
        if (component.$refs.check) {
          component.$refs.check.focus()
          return true
        }
      }
      let focused = false
      component.$children.some((child) => {
        focused = this.findFirstError(child)
        return focused
      })

      return focused
    },
    validate() {
      this.$v.$touch()
      this.$nextTick(() => this.findFirstError())
      return this.isValid
    }
  }
}
</script>

<style scoped>
.btn.disabled {
  cursor: auto;
}
.preview {
  background-color: white;
  border: 1px solid #DDD;
  padding: 5px;
  width: 50%;
  height: 50%;
}
.image-preview {
  padding: 20px;
}

.invalid-feedback {
    font-size: 100% !important;
}
.notValidate {
  border: #f86c6b 1px solid;
}
#contentFeedback {
  color: #f86c6b !important;
  font-size: 100%;
}
</style>
<style>
/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
}

</style>
<style src="spinkit/scss/spinkit.scss" lang="scss" />
